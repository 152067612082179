<template>
  <v-app>
    <ValidationErrors/>

    <v-container
      fill-height
    >
      <v-row
        justify="center"
        align="center"
      >
        <v-col
          class="col-sm-6 col-md-4"
        >
          <slot/>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import ValidationErrors from '../components/ValidationErrors'

export default {
  name: 'AuthLayout',

  metaInfo () {
    return {
      title: this.title,
      titleTemplate: '%s | Revision Tool',
    }
  },

  props: {
    title: String,
  },

  components: {
    ValidationErrors,
  },
}
</script>
