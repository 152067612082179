<template>
  <AuthLayout title="Register">
    <v-form
      @submit.prevent="register(form)"
    >
      <v-text-field
        v-model="form.name"
        label="Name"
        autocomplete="name"
      ></v-text-field>

      <v-text-field
        v-model="form.email"
        label="Email"
        autocomplete="username"
      >
      </v-text-field>

      <v-text-field
        v-model="form.password"
        type="password"
        label="Password"
        autocomplete="new-password"
      >
      </v-text-field>

      <v-text-field
        v-model="form.password_confirmation"
        type="password"
        label="Confirm Password"
        autocomplete="new-password"
      ></v-text-field>

      <v-text-field
        v-model="form.registration_key"
        label="Registration key"
      ></v-text-field>

      <v-btn
        type="submit"
        color="primary"
      >
        Register
      </v-btn>
    </v-form>
  </AuthLayout>
</template>

<script>
import AuthLayout from '../../layouts/AuthLayout'
import { mapActions } from 'vuex'

export default {
  name: 'Register',

  components: {
    AuthLayout,
  },

  data: () => ({
    form: {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      registration_key: '',
    },
  }),

  methods: {
    ...mapActions('user', ['register']),
  },
}
</script>
